.navbar {
    background: #ffffff;
    padding: 2rem 5rem;
    
    border-bottom: 1px solid #D2DCFF;
    border-radius: 0 0 2.5rem 2.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-logo {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &-links {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        & a {

            &:not(:last-child) {
                margin-right: 10rem;
            }
        }
    }
}

@media (max-width: 768px) {
    
    .navbar {

        &-logo {
            width: 100%;
        }

        &-links {
            margin-top: 1.5rem;
            flex-direction: column;
            align-items: flex-start;

            & a {
                margin-bottom: 1.5rem;
            }
        }
    }
}