.hero {
    padding: 5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-content {
        width: 30%;

        & .h1 {
            margin-bottom: 1.5rem;
        }

        & .p {
            margin-bottom: 1rem;
        }
    }

    &-image {
        width: 55%;

        & img {
            width: 100%;
        }
    }
}

.join-us {
    background: #ffffff;
    padding: 5rem;
    margin: 5rem 0;

    border: 1px solid #D2DCFF;
    border-radius: 1rem;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &-content {
        width: 30%;

        & .h1 {
            margin-bottom: 1.5rem;
        }
    }
}

@media (max-width: 768px) {
    
    .hero {
        flex-direction: column-reverse;

        &-content {
            width: 100%;
        }

        &-image {
            width: 100%;
            margin-bottom: 5rem;
        }
    }

    .join-us {

        &-content {
            width: 45%;
        }
    }
}

@media (max-width: 568px) {

    .join-us {
        flex-direction: column;
        align-items: flex-start;

        &-content {
            width: 100%;
            margin-bottom: 2.5rem;
        }
    }
}