.footer {
    background: #ffffff;
    padding: 2rem 5rem;
    
    border-top: 1px solid #D2DCFF;
    border-radius: 2.5rem 2.5rem 0 0;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 992px) {
    
    .footer {
        flex-direction: column;

        &-logo {
            margin-bottom: 1.5rem;
        }
    }
}