@import url("./text.scss");
@import url("./button.scss");

html {
  font-size: 10px;
}

* {
  font-family: "Quicksand" !important;
}

body {
  background: #F7FAFF;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

.page {
  padding: 0 5rem;
}