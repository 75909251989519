.button {
    cursor: pointer;
    font-weight: 600;
    
    padding: 1rem 3rem;
    border-radius: 0.5rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &-filled-accent {
        color: #ffffff;
        background: #4795D0;
        border: 1px solid#4795D0;
        box-shadow: 0 0.5rem 1.5rem 0 rgba(#4795D0, 0.3);
    }
}