.our-team {
    
    & .h1 {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    & .p-large {
        width: 50%;
        margin: auto;
        text-align: center;
        line-height: 1.8;
        margin-bottom: 5rem;
    }

    & .team-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        & .person {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            width: 50%;
            border-radius: 1rem;
            border: 1px solid #d2dcff;
            padding: 0 2.5rem;

            & img {
                width: 30%;
                height: 100%;
                border-radius: 1rem;
            }

            &-content {
                padding: 1.5rem 0 1.5rem 2.5rem;
            }

            &:not(:last-child) {
                margin-bottom: 2.5rem;
            }
        }
    }
}


@media(max-width: 992px) {
    
    .our-team {

        & .p-large {
            width: 75%;
        }

        & .team-row {
            
            & .person {
                width: 75%;
            }
        }
    }
}

@media(max-width: 768px) {

    .our-team {

        & .p-large {
            width: 100%;
        }

        & .team-row {

            & .person {
                flex-direction: column;
                padding: 0;
                
                width: 50%;

                &-content {
                    padding-right: 2.5rem;
                }

                & img {
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width: 576px) {

    .our-team {

        & .team-row {

            & .person {
                width: 100%;
            }
        }
    }
}