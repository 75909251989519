.p {
    font-size: 1.3rem;
}

.p-large {
    font-size: 1.5rem;
}

.h1 {
    font-size: 2.6rem;
    font-weight: 600;
}

.h2 {
    font-size: 2rem;
    font-weight: 600;
}

.h4 {
    font-size: 1.3rem;
    font-weight: 400;
    text-transform: uppercase;
}

.p, .p-large, .h1, .h2, .h4 {
    
    &-bold {
        font-weight: 600;
    }

    &-black {
        color: #000000;
    }

    &-accent {
        color: #4795D0;
    }
}