.articles {
    
    & .h1 {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    & .articles-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;


        & .article {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            width: 25%;
            border-radius: 1rem;
            border: 1px solid #d2dcff;
            padding: 2.5rem;

            &-content {
                padding: 1.5rem 0 1.5rem 2.5rem;
            }

            &:not(:last-child) {
                margin-right: 2.5rem;
            }

            // &:not(:last-child) {
            //     margin-bottom: 2.5rem;
            // }
        }
    }    
}


@media(max-width: 992px) {
    
    .articles {

    }
}

@media(max-width: 768px) {

    .articles {

    }
}

@media(max-width: 576px) {

    .articles {
        
    }
}