.article {
    
    & .h1 {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 1.5rem;
    }

    & .h2 {
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }

    & .h3 {
        text-align: center;
        margin-bottom: 1.5rem;
    }


    & p {
        font-size: 1.5rem;
    }
}


@media(max-width: 992px) {
    
    .article {

    }
}

@media(max-width: 768px) {

    .article {

    }
}

@media(max-width: 576px) {

    .article {
        
    }
}